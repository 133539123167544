import React from "react";

import styled from "styled-components";
import { motion } from "framer-motion";

import { Link } from "react-router-dom";
import { Container, LeftSection, RightSection, Buttons } from "./styles";

export const PreviewDemo = () => {
  const ToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <Styles>
      <p>Check out our interactive demo.</p>
      <Link
        to="/prototype"
        style={{
          textDecoration: "none",
          color: "inherit",
        }}
      >
        <Buttons>
          <div className="main second" onClick={ToTop}>
            Press here
          </div>
        </Buttons>
      </Link>
    </Styles>
  );
};

const Styles = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1300px;
  gap: 40px;
  margin-top: 20px;
  width: 100%;
  p {
    font-size: 16px;
    font-weight: 500;
    color: #434343;
  }
  .second {
    min-width: 180px;
  }

  @media screen and (max-width: 700px) {
    flex-direction: column;
    gap: 20px;
    margin-bottom: 40px;
    text-align: center;

    button {
      width: 100%;
    }
  }
`;
