import React from "react";
import { Loading } from "./sections/Loading";
import styled from "styled-components";

export default function LoadingPage() {
  return (
    <Styles>
      <Loading />
    </Styles>
  );
}

const Styles = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
