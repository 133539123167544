import Cover from "../assets/coverImage2.png";

import styled from "styled-components";
import React, { useState } from "react";
const youtubeUrl =
  "https://www.youtube.com/embed/_8i92-iM1_I?autoplay=1&mute=0&loop=1&controls=1&rel=0";

export default function Image() {
  const [show, setShow] = useState(false);

  const CloseModal = (e) => {
    if (e.target.className === "container") {
      setShow(!show);
    }
  };
  const handleKeyDown = () => {
    document.addEventListener("keydown", (event) => {
      if (event.keyCode == 27) {
        setShow(false);
      }
    });
  };

  React.useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    if (!show) document.body.style.overflow = "visible";
    else document.body.style.overflow = "hidden";
    // cleanup this component
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [show]);
  return (
    <Styles>
      <img
        onClick={() => setShow(!show)}
        src={Cover}
        className="cover"
        alt="Thumbnail"
      />

      {show && (
        <div className="container" onClick={CloseModal}>
          <div className="youtube-video-container">
            <iframe
              className="youtube-video"
              src={youtubeUrl}
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      )}
    </Styles>
  );
}

const Styles = styled.div`
  .cover {
    width: 100%;
    cursor: pointer;
    object-fit: cover;
  }

  .container {
    background-color: rgba(0, 0, 0, 0.86);
    width: 100vw;
    min-height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;

    .youtube-video-container {
      max-width: 70%;
      width: 100%;
      aspect-ratio: 16 / 9;
    }

    .youtube-video {
      width: 100%;
      height: 100%;
    }
  }
`;
