import React, { useState, useEffect } from "react";

import styled from "styled-components";
import { motion } from "framer-motion";

import Play from "../assets/play.svg";

import { data } from "../assets/Text.js";

import { FullVideoModal } from "./FullVideo";

import { Element } from "react-scroll";

import { container, videoAnime, textAnime } from "../Animations";

const figmaUrl =
  "https://www.figma.com/proto/iGUNJW2nT3hE1zrCiZbn7m/Prototype---StarAthlete?embed_host=share&kind=&node-id=52%3A2609&page-id=0%3A1&scaling=scale-down-width&starting-point-node-id=52%3A2609&viewport=241%2C48%2C0.51&hide-ui=1";

function Video() {
  const [openVideo, setOpenVideo] = useState(false);
  const FullVideo = () => {
    setOpenVideo(true);
  };

  useEffect(() => {
    if (openVideo) document.body.style.overflow = "hidden";
    else document.body.style.overflow = "visible";
  }, [openVideo]);

  return (
    <Styles>
      {/* <motion.div className="why-text" variants={textAnime}>
         <h1 className="why">{data.videoTitle}</h1>
        <p>{data.videoSubTitle}</p>
      </motion.div> */}
      <div className="content">
        <h2 className="proto-text"></h2>
        <div className="proto">
          <iframe
            style={{ border: "1px solid #ECE8D9" }}
            width="345px"
            height="745px"
            src={figmaUrl}
          ></iframe>
        </div>
        <h2 className="proto-text player"></h2>
      </div>
    </Styles>
  );
}

const Styles = styled(motion.div)`
  background: #131313;

  /* color: white; */
  padding: 40px;
  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
      font-weight: 600;
      font-size: 84px;
      color: rgba(12, 45, 126, 1);
    }
    .player {
      color: #0c557e;
    }
  }

  h1 {
    text-align: center;
  }
  p {
    max-width: 800px;
    margin: 20px 0 40px 0;
  }
  .why-text {
    padding: 40px 0 20px 0;
  }

  .video-container {
    position: relative;
    width: 100%;
    max-height: 650px;
    min-height: 150px;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    aspect-ratio: 16/9;
    box-shadow: rgb(149 157 165 / 100%) 0 8px 24px;
  }
  .button {
    width: 100%;
    text-align: center;
    position: absolute;

    cursor: pointer;
    padding: 30px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    outline: none;
    border: none;
    .button-bg {
      background-color: rgba(0, 0, 0, 0.3);
      height: 80px;
      width: 120px;
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.5s ease;
      z-index: 2;
    }
    :hover {
      .button-bg {
        background-color: rgba(0, 0, 0, 1);
      }
    }

    img {
      width: 30px;
    }
  }

  .videoDiv {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    iframe {
      width: 100%;
      aspect-ratio: 16/9;
    }
    .color-overlay {
      position: absolute;
      top: 0;
      left: 0;
      background-color: #181818;
      width: 100%;
      height: 100%;
      opacity: 0.6;
    }
  }
  @media screen and (min-width: 700px) {
    text-align: center;
    padding: 40px;
  }
  @media screen and (min-width: 1000px) {
    .why-text {
      padding: 100px 0 100px 0;
    }
  }
`;

export default Video;
