import React from "react";

import styled from "styled-components";
import { motion } from "framer-motion";

import CloseIcon from "@material-ui/icons/Close";

export const FullVideoModal = ({ setOpenVideo }) => {
  const CloseModal = () => {
    setOpenVideo(false);
  };

  const ClickBgClose = (e) => {
    if (e.target.className === "background") {
      setOpenVideo(false);
    }
    if (e.keyCode === 27) {
      setOpenVideo(false);
    }
  };
  return (
    <Styles>
      <div className="background" onClick={ClickBgClose}>
        <div className="container">
          <div className="close" onClick={CloseModal}>
            <CloseIcon />
          </div>
          {/* <iframe
            title="StarAthlete promo video"
            id="ytplayer"
            type="text/html"
            src="https://www.youtube.com/embed/HN0htDa_aj0?autoplay=1&cc_load_policy=1&controls=1&disablekb=1&fs=0&loop=1&modestbranding=1&playsinline=1&start=00&rel=0"
            frameBorder="0"
            allowFullScreen
          /> */}

          {/* <iframe
            // width="560"
            // height="315"
            src="https://www.youtube.com/embed/_8i92-iM1_I?autoplay=1&cc_load_policy=1&controls=0&disablekb=0&loop=1&modestbranding=1&playsinline=1&start=01&mute=0&rel=0"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe> */}
        </div>
      </div>
    </Styles>
  );
};

const Styles = styled(motion.div)`
  .background {
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow-y: hidden;
    z-index: 10;
    padding: 20px;
  }
  .container {
    padding: 20px;
    width: 100%;
    max-height: calc(100% + 10px);
    background: #e9ecef;
    aspect-ratio: 16/9;
  }
  iframe {
    width: 100%;
    height: 100%;
    max-height: 720px;
  }

  .close {
    float: right;
    margin-bottom: 10px;
  }
  @media screen and (min-width: 1300px) {
    .container {
      width: 1300px;
    }
  }
`;
