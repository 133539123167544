import React from "react";
import { Link } from "react-router-dom";

import CookieConsent from "react-cookie-consent";

export const CookieNew = () => {
  return (
    <CookieConsent
      fontSize="14px"
      style={{ background: "#2B373B", fontSize: "14px" }}
      buttonText="I accept"
    >
      We use cookies for basic functionality and optimizing your experience. For
      more information please check our{" "}
      <Link
        to="/privacy"
        style={{
          textDecoration: "underline",
          color: "#fff",
          fontWeight: "600",
          fontSize: "14px",
        }}
      >
        Privacy page
      </Link>
    </CookieConsent>
  );
};
