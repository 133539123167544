export const headerBtnAnime = {
  initial: { opacity: 0, y: -1 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -50 },
};

//Display the text
export const textAnime = {
  initial: { opacity: 0, y: 105 },
  animate: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  // exit: { opacity: 0, y: -50 },
};

export const showImageRight = {
  initial: { opacity: 0, x: 340 },
  animate: { opacity: 1, x: 0, transition: { duration: 0.3 } },
  // exit: { opacity: 0, y: -50 },
};

export const showImageLeft = {
  initial: { opacity: 0, x: -340 },
  animate: { opacity: 1, x: 0, transition: { duration: 0.3 } },
  // exit: { opacity: 0, y: -50 },
};

export const container = {
  initial: { opacity: 0 },
  animate: {
    opacity: 1,
    transition: {
      staggerChildren: 0.5,
    },
  },
};

export const videoAnime = {
  initial: { opacity: 0, scale: 0.4 },
  animate: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.4,
    },
  },
};
