import React, { useState, useEffect, Suspense, lazy } from "react";
import { HeadProvider, Title } from "react-head";

import { Element } from "react-scroll";

import styled from "styled-components";
import { motion } from "framer-motion";

import { Header } from "./sections/Header";
import { Heading } from "./sections/Heading";
import { Heading2 } from "./sections/Heading2";

import ReactGA from "react-ga";

import { CookieNew } from "./sections/CookieNew";

import ImageHead from "./assets/sports-bg 2.webp";
import ImageHeadMbile from "./assets/sports-bg-mobile 2.webp";

import { Modal } from "./sections/Modal";
import { useMediaQuery } from "react-responsive";
import Box2 from "./sections/Box2";
import Box3 from "./sections/Box3";
import Box from "./sections/Box";
import Footer from "./sections/Footer";
import Video from "./sections/Video";
import { PreviewDemo } from "./sections/PreviewDemo";

const Content = lazy(() => import("./Content"));

ReactGA.initialize("G-8KB9DE1PKX");
ReactGA.pageview(window.location.pathname);

const HomePage = () => {
  //understand when to open the component for successful registration
  const [modal, setModal] = useState(false);
  //get the value from the input
  const [value, setValue] = useState("");
  //Hide cookies
  // const [openCookies, setCookies] = useState(true);
  //Hook for an existing user
  const [exists, setExists] = useState(false);
  //if a user watched the whole video
  const [finished, setFinished] = useState(false);
  //wait for api call to return
  const [isLoading, setLoading] = useState(true);
  //if there is an error while getting the api request
  const [isError, setError] = useState(false);

  useEffect(() => {
    if (modal) document.body.style.overflow = "hidden";
    else document.body.style.overflow = "visible";
  }, [modal]);

  const isMobile = useMediaQuery({
    query: "(max-device-width: 700px)",
  });

  return (
    <Styles>
      <HeadProvider>
        <Title>StarAthlete</Title>
      </HeadProvider>
      {/* <div className="bg">
        <div className="layer"></div>
        <img
          src={isMobile ? ImageHeadMbile : ImageHead}
          alt="Field and stadium"
        />
      </div> */}

      {/* {openCookies && <Cookies setCookies={setCookies} />} */}
      {modal && (
        <Modal
          isLoading={isLoading}
          setLoading={setLoading}
          isError={isError}
          setError={setError}
          exists={exists}
          value={value}
          setModal={setModal}
        />
      )}
      {/* top site bar */}
      <Header />

      <Heading
        modal={modal}
        setModal={setModal}
        value={value}
        setValue={setValue}
        exists={exists}
        setExists={setExists}
        isLoading={isLoading}
        setLoading={setLoading}
        isError={isError}
        setError={setError}
      />

      <div className="content-width setPadding">
        <Box />
        {/* <Box2 /> */}
        <Box3 />
      </div>
      <Footer />

      {/* <Content
        finished={finished}
        setFinished={setFinished}
        modal={modal}
        setModal={setModal}
        value={value}
        setValue={setValue}
        exists={exists}
        setExists={setExists}
        isLoading={isLoading}
        setLoading={setLoading}
        isError={isError}
        setError={setError}
      /> */}

      <CookieNew />
    </Styles>
  );
};

const Styles = styled(motion.div)`
  .content-width {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1000;
    width: 100%;
    .layer {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: calc(100% - 10px);
    }

    img {
      height: calc(100% + 40px);
      width: 100%;
      object-fit: cover;
    }
  }
`;

export default HomePage;
