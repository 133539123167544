export var data = {
  //Navigation components
  logoLabel: "StarAthlete",
  buttonNav: "Join",
  //Heading section
  mainTitle: "Connect with your sport network",
  mainDescription:
    "The sports platform that brings together scouts and athletes with ease",
  buttonLabel: "Join",
  placeholder: "Enter your email",
  watchBtn: "Watch video",

  //Video section
  videoTitle: "What is StarAthlete?",
  videoSubTitle:
    "Aspiring athletes can connect with teammates, coaches and scouts and showcase their skills and achievements at the same time. Scouting has never been easier.",
  //Usp section
  // uspTitle: "What can you do?",
  uspSectionTitle: "Enter usp section title here",

  uspTitle1: "🔗 Connect with your teammates and scouts",
  uspeDescription1:
    "In today’s world you can connect with anyone, anywhere, use StarAthlete to find your dream team. Then connect with scouts and apply for the team’s tryouts.",

  uspTitle2: "📸 Showcase your skills to scouts, coaches & teams",
  uspeDescription2:
    "Upload highlight videos of yourself or doing some of your favourite drills and get noticed by scouts",

  // uspTitle3: "Best practices and drills",
  uspTitle3: "🔎 Look for your next star",
  uspeDescription3:
    "Streamline your search! Use StarAthlete as the platform for finding and connecting with talented players. Post your open positions, or fill in your search details and find the best athletes for your team!",

  //
  bigCtaTitle: "If you are ready to become a star,",

  //Success texts
  succHeader: "Thank you!",
  succParagraph:
    "You are one of the first members on our platform. We will keep you posted on our progress.",

  //Why sign up now
  whyNowTitle: "Benefits of joining early",
  reason1: "Free premium for 3 months when we launch",

  reason2: "Recieve frequent status update on our progress",

  reason3: "Your account will show up at the top of scout searches",
  //Footer
  feedback: "We`d love to hear your feedback.",
  subText: "Feel free to share your suggestions with us!",
  email: "contact@starathlete.co",
};
