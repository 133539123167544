import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

import { animateScroll as scroll, scroller } from "react-scroll";

import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";

import Logo from "../assets/Logo.webp";
import { data } from "../assets/Text.js";

import { useScrollYPosition } from "react-use-scroll-position";

export const Header = () => {
  const [navBtn, setNavBtn] = useState(false);
  const [inPrivacy, setInPrivacy] = useState(false);

  const scrollY = useScrollYPosition();

  const location = useLocation().pathname.split("/")[1];

  useEffect(() => {
    if (
      location === "privacy" ||
      location === "prototype" ||
      location === "general" ||
      location === "athletes" ||
      location === "coaches" ||
      location === "pitch" ||
      location === "executive" ||
      location === "about"
    ) {
      setInPrivacy(true);
      setNavBtn(true);
    }
    if (location === "" && scrollY > 550) {
      setNavBtn(true);
    }

    if (location === "prototype") {
      setNavBtn(true);
    }
    if (location === "" && scrollY < 550) {
      setNavBtn(false);
    }
  }, [scrollY, location]);

  //Go to enter your email section
  function ScrowToCTA() {
    scroller.scrollTo("subscribeNow", {
      duration: 1000,
      delay: 20,
      smooth: true,
      offset: -150,
    });
  }
  function HomePage() {
    scroller.scrollTo("subscribeNow", {
      duration: 1000,
      delay: 20,
      smooth: true,
      offset: -150,
    });
    window.open("/", "_current");
  }

  return (
    <Styles className="setPadding">
      <div className="wholeNav">
        <Link to="/" style={{ textDecoration: "none" }}>
          <div className="logo" onClick={() => scroll.scrollToTop()}>
            <img src={Logo} alt="StarAthlete logo" />
            <h5>{data.logoLabel}</h5>
          </div>
        </Link>

        {/* <div
          className="header-button"
          id="header-button"
          onClick={!inPrivacy ? () => scroll.scrollToTop() : HomePage}
        >
          Join waitinglist
        </div> */}
      </div>
    </Styles>
  );
};

const Styles = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 0;
  left: 0;
  padding-top: 10px;
  padding-bottom: 0;
  z-index: 3;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 8px 11px -4px rgb(0 0 0 / 10%);

  /* backdrop-filter: blur(11px);
  -webkit-backdrop-filter: blur(11px); */
  width: 100%;

  .wholeNav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 60px;
    max-width: 1300px;
  }

  .logo {
    display: flex;
    align-items: center;
    cursor: pointer;

    img {
      width: 40px;
      margin-right: 10px;
    }
    h5 {
      color: #333300;
    }
  }
  @media screen and (max-width: 600px) {
    .logos {
      margin-bottom: 0px;
    }
  }
`;
