import React from "react";
import styled from "styled-components";

// Upload the icon and import it here.
import Medal from "../assets/medal.svg";
import AccountCircleSharpIcon from '@mui/icons-material/AccountCircleSharp';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import { Container } from "./styles";

export default function Box() {
  return (
    <Styles className="global-margin center">
      <div className="why-text">
        <h1 className="why">What is StarAthlete?</h1>
        <p>
          StarAthlete's mission is to empower you - the athlete, to showcase your talent to scouts as easily and efficiently as possible and find a new team.
        </p>
      </div>
      <div class="grid">
        <div class="box">
          <div class="icon">
            {/* Change the icon by changing the name in src. First import the file (check up) */}
            {/* <img src={Medal} alt="icon" /> */}
            <AccountCircleSharpIcon className="muiIconClass" />
            
          </div>
          <div class="text">
            <h3>Create and promote your profile</h3>
            <div class="paragraph">
              Easily upload your video highlights, stats and previous achievements
            </div>
          </div>
        </div>
        <div class="box">
          {/* <img src={Medal} alt="icon" /> */}
          <PersonSearchIcon className="muiIconClass" />
          <div class="text">
            <h3>Advanced search & notifications of open positions</h3>
            <div class="paragraph">
              Choose what you are looking for and let us find the team that fits your style and profile
            </div>
          </div>
        </div>
        <div class="box">
          <div class="icon"></div>
          {/* <img src={Medal} alt="icon" /> */}
          <MoneyOffIcon className="muiIconClass" />
          <div class="text">
            <h3>Save up to 20% on Agent Fees</h3>
            <div class="paragraph">
              Negotiate directly with teams and save up to 20% of you future salary
            </div>
          </div>
        </div>
      </div>
    </Styles>
  );
}

const Styles = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1300px;
  width: 100%;
  .muiIconClass{
    font-size: 70px; 
  }
  .why-text {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    h1 {
      font-size: 64px;
    }
    p {
      font-size: 24px;
      max-width: 600px;
    }
  }

  .paragraph {
    max-width: 350px;
    font-size: 18px;
  }
  h3 {
    font-weight: 600;
    margin-bottom: 20px;
  }
  .grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* create a 3-column grid */
    grid-gap: 30px; /* add some space between the boxes */

    img {
      margin-bottom: 5px;
      max-width: 75px;
    }
  }

  @media screen and (max-width: 1000px) {
    align-items: flex-start;
    .grid {
      grid-template-columns: 1fr;
    }
  }

  @media screen and (max-width: 800px){
    .muiIconClass{
    font-size: 45px; 
  }
  }
`;
