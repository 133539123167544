import React from "react";
import styled from "styled-components";

import Medal from "../assets/medal.svg";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import ChatIcon from "@mui/icons-material/Chat";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import SpokeIcon from "@mui/icons-material/Spoke";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import { Container } from "./styles";

export default function Box2() {
  return (
    <Styles className="global-margin">
      <div className="why-text">
        <h1 className="why">Features</h1>
        <p>
          Our goal is to design a platfarm that will make the process of
          connecting football players with teams and scouts as seamless and
          efficient as possible.
        </p>
      </div>
      <div class="grid">
        <div class="grid-item">
          <div class="box">
            <div class="icon">
              <PersonSearchIcon />
            </div>
            <div class="text">
              <p>Advanced search and filtering</p>
            </div>
          </div>
        </div>
        <div class="grid-item">
          {" "}
          <div class="box">
            <div class="icon">
              <ChatIcon />
            </div>
            <div class="text">
              <p>Direct player-scout communication</p>
            </div>
          </div>
        </div>
        <div class="grid-item">
          {" "}
          <div class="box">
            <div class="icon">
              <PersonOffIcon />
            </div>
            <div class="text">
              <p>Eliminating the need for agents</p>
            </div>
          </div>
        </div>

        <div class="grid-item">
          {" "}
          <div class="box">
            <div class="icon">
              <AccountBoxIcon />
            </div>
            <div class="text">
              <p>Professional player profiles</p>
            </div>
          </div>
        </div>

        <div class="grid-item">
          {" "}
          <div class="box">
            <div class="icon">
              <SpokeIcon />
            </div>
            <div class="text">
              <p>Conencting with other players</p>
            </div>
          </div>
        </div>
        <div class="grid-item">
          {" "}
          <div class="box">
            <div class="icon">
              <CloudUploadIcon />
            </div>
            <div class="text">
              <p>Video and image uploading</p>
            </div>
          </div>
        </div>
      </div>
    </Styles>
  );
}

const Styles = styled.div`
  max-width: 1300px;
  width: 100%;
  .why-text {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    h1 {
      font-size: 64px;
    }
    p {
      font-size: 24px;
      max-width: 600px;
    }
  }
  .grid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* create a 4-column grid */
    grid-gap: 30px; /* add some space between the grid items */
  }

  /* Make the grid items flex containers */
  .grid-item {
    .icon {
      svg {
        font-size: 40px;
      }
    }
    p {
      max-width: 280px;
      margin-top: 10px;
    }
  }

  @media screen and (max-width: 768px) {
    .grid {
      grid-template-columns: repeat(
        2,
        1fr
      ); /* create a 2-column grid on small screens */
    }
  }

  @media screen and (max-width: 480px) {
    .grid {
      grid-template-columns: repeat(
        1,
        1fr
      ); /* create a 1-column grid on extra small screens */
    }
  }
`;
