import React from "react";
import { Link } from "react-router-dom";

import styled from "styled-components";
import { motion } from "framer-motion";

import { data } from "../assets/Text.js";
import { textAnime } from "../Animations";

import FB from "@material-ui/icons/Facebook";
import IG from "@material-ui/icons/Instagram";

function Footer() {
  const ToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <Styles
      className="setPadding"
      variants={textAnime}
      initial="initial"
      animate="animate"
    >
      <div className="heading">
        <h6>{data.feedback}</h6>
        <span>{data.subText}</span>
        <a className="mail-href" href="mailto:contact@starathlete.co">
          <p>{data.email}</p>
        </a>
        <div className="logos">
          <a
            className="fb"
            href="https://www.facebook.com/TheStarAthlete"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FB />
          </a>
          <a
            className="ig"
            href="https://www.instagram.com/starathlete_official/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <IG />
          </a>
        </div>
      </div>

      <div className="foot">
        <div className="line"></div>
        <div className="rights">
          <div>&copy; 2023 All rights reserved</div>
          <div>
            <Link
              to="/privacy"
              style={{
                textDecoration: "none",
                color: "inherit",
              }}
            >
              <span onClick={ToTop} className="privacy">
                Privacy policy
              </span>
            </Link>
          </div>
        </div>
      </div>
    </Styles>
  );
}

const Styles = styled(motion.div)`
  height: 300px;
  width: 100%;
  background: #030c12;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .heading {
    text-align: center;
    span {
      font-size: 14px;
      color: #d8d8d8;
    }
    .mail-href {
      text-decoration: none;
      color: inherit;
      transition: all 0.5s ease-out;
      :hover {
        color: green;
      }
    }
    p {
      margin-top: 20px;
      font-size: 18px;
      font-weight: 700;
      cursor: pointer;
    }
  }
  .logos {
    margin: 10px 0 40px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 50px;
    svg {
      cursor: pointer;
      font-size: 32px;
      transition: all 0.3s ease-out;
      color: white;
    }
    .fb {
      width: 40px;
      svg {
        :hover {
          color: #4267b2;
          font-size: 40px;
        }
      }
    }
    .ig {
      width: 40px;
      svg {
        :hover {
          font-size: 40px;
          color: #f77737;
        }
      }
    }
  }
  .privacy {
    margin-right: 20px;
    cursor: pointer;
    transition: 0.5s all;
    :hover {
      color: white;
    }
  }
  .foot {
    .rights {
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      color: #b1b1b1;
    }
    .line {
      height: 1px;
      width: 100%;
      background: #424242;
      margin-bottom: 8px;
    }
  }
  @media screen and (min-width: 1023px) {
    margin-top: 100px;
  }
  @media screen and (max-width: 600px) {
    .logos {
      margin-bottom: 0px;
    }
  }
`;

export default Footer;
