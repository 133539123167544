import React, { useEffect, useState } from "react";
import axios from "axios";

import styled from "styled-components";
import { motion } from "framer-motion";

import { data } from "../assets/Text.js";

import CheckIcon from "@material-ui/icons/Check";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

import { Loading } from "./Loading.jsx";

export const Modal = ({
  isError,
  setError,
  isLoading,
  setLoading,
  exists,
  value,
  setModal,
}) => {
  const [getDate, setDate] = useState(null);
  useEffect(() => {
    debugger;
    axios
      .get(
        "https://starathletepromo.azurewebsites.net/api/Service.svc/getuserdata/" +
          value
      )
      .then((res) => {
        debugger;
        const registeredAt = res.data.Users[0].created_at;
        setDate(registeredAt);
      })
      .catch((error) => {
        console.log(error);
        //setError(true);
      });
  });
  return (
    
    <Styles>
      <div className="container">
        {isLoading ? (
          <div className="loading-indicator">
            <Loading />
          </div>
        ) : !isError ? (
          !exists ? (
            <>
              <div className="image">
                <CheckIcon />
              </div>
              <h3>
                {data.succHeader}
                {/* <br></br>
                <span>{value}!</span> */}
              </h3>
              <p>{data.succParagraph}</p>
              <button onClick={(e) => setModal(false)}>
                Back to the website
              </button>
            </>
          ) : (
            <>
              <div className="image already-reged">
                <PeopleAltIcon />
              </div>
              <h3>
                <span>{value}</span>
                <br></br>
                You are already one of us!
              </h3>
              {/* <p>
                You have successfully registered at <br />
                <b>📅 {getDate}</b>
              </p> */}
              <button onClick={(e) => setModal(false)}>
                Back to the website
              </button>
            </>
          )
        ) : (
          <>
            <div className="image api-error">
              <ErrorOutlineIcon />
            </div>
            <h3>
              We are sorry about this! <br />
              Please reload the page
            </h3>
            <p>
              If the problem continues please{" "}
              <b>
                <a href="mailto:contact@starathlete.co">email us</a>
              </b>
              .
            </p>
            <button
              onClick={(e) => (window.location = "https://starathlete.co/")}
            >
              Reload
            </button>
          </>
        )}
      </div>
    </Styles>
  );
};

const Styles = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100vw;
  overflow-y: auto;
  z-index: 100001;
  padding: 10px;

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    max-width: 600px;
    width: 100%;
    max-height: 500px;
    background: #e9ecef;
    text-align: center;
  }
  .loading-indicator {
    padding: 40px 0 40px 0;
  }
  button {
    width: 210px;
  }
  .image {
    width: 50px;
    height: 50px;
    border-radius: 500px;
    border: 2px solid #85c441;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      color: #85c441;
      font-size: 32px;
    }
  }
  .already-reged {
    border: 2px solid #0c2d7e;
    svg {
      color: #0c2d7e;
    }
  }
  .api-error {
    border: none;
    svg {
      font-size: 60px;

      color: #b6273a;
    }
  }
  a {
    text-decoration: none;
    color: inherit;
  }
  h3 {
    margin: 20px 0 40px 0;
    span {
      color: #0c2d7e;
      font-size: 21px;
    }
  }
  p {
    margin-bottom: 40px;
    max-width: 400px;
  }
  @media screen and (min-width: 700px) {
    .container {
      padding: 40px;
    }
    h3 {
      span {
        font-size: 28px;
      }
    }
  }
`;
