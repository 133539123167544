import React from "react";
import axios from "axios";
import { data } from "../assets/Text.js";
import styled from "styled-components";

export const InputComponent = ({
  setExists,
  modal,
  setModal,
  value,
  setValue,
  setLoading,
  setError,
}) => {
  function SubmitMail(e) {
    e.preventDefault();
    setModal(!modal);
    var data = {
      user_email: value,
      user_type: "user",
      feedback: ""
    };
    let urlForPost =
      "https://starathletepromo.azurewebsites.net/api/Service.svc/saveform";
    //console.log(JSON.stringify(data));
    axios
      .post(urlForPost, data)
      .then((res) => {
        debugger;
        if (res.data.status === 0) {
          //user exists
          setExists(true);
          setLoading(false);
        } else if (res.data.status === 1) {
          //user doesn`t exist
          setExists(false);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setError(true);
      });
  }

  return (
    <form className="" onSubmit={SubmitMail}>
      <Styles>
      <input
        value={value}
        onChange={(e) => setValue(e.target.value)}
        type="email"
        required
        placeholder={data.placeholder}
        className="drop-shadow"
      />
      
        <button className="drop-shadow input-button">{data.buttonLabel}</button>
      </Styles>
    </form>
  );

};
const Styles = styled.div`

@media screen and (max-width: 800px) {
  display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
} 

`;


