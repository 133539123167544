import React from "react";
import styled from "styled-components";

import Image from "./Image";
import { Link, useLocation } from "react-router-dom";
import EastIcon from "@mui/icons-material/East";
import { InputComponent } from "./InputComponent";
import { Container, LeftSection, RightSection, Buttons } from "./styles";
import { PreviewDemo } from "./PreviewDemo";

export const Heading = ({
  exists,
  setExists,
  modal,
  setModal,
  value,
  setValue,
  isLoading,
  setLoading,
  isError,
  setError,
}) => {
  return (
    <Styles className="flex setPadding">
      <Container className="container set-vh">
        <LeftSection className="leftSection">
          <div className="text">
            <h1>Find your next team on StarAthlete</h1>
            <p>
              The platform that connects athletes with top teams and scouts!
              {/* Showcase your talent by uploading your highlights and find a new team! */}
            </p>
            <br></br>
            <p style={{ fontWeight: "700" }}>
              Are you ready to find your next team?
            </p>
            <Buttons className="buttons">
              {/* <Link to="/athletes" style={{ textDecoration: "none" }}>
                <div className="main" id="join">
                  Join now
                  <EastIcon />
                </div>
              </Link> */}

              <InputComponent
                modal={modal}
                setModal={setModal}
                value={value}
                setValue={setValue}
                exists={exists}
                setExists={setExists}
                isLoading={isLoading}
                setLoading={setLoading}
                isError={isError}
                setError={setError}
              />

              {/* <Link to="/coaches" style={{ textDecoration: "none" }}>
                <div className="main second" gap="20px">
                  Scout
                </div>
              </Link> */}
            </Buttons>
          </div>
        </LeftSection>
        <RightSection className="rightSection">
          <Image />
          <PreviewDemo />
        </RightSection>
      </Container>
    </Styles>
  );
};

const Styles = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* Left section starts */
  .leftSection {
    .text {
      h1 {
        text-align: left;
      }
      p {
        font-size: 20px;
        font-weight: 500;
        text-align: left;
        max-width: 500px;
      }
    }
    .buttons {
      margin-top: 20px;
      display: flex;
      gap: 20px;
    }
    #join {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 15px;
      svg {
        font-size: 22px;
      }
    }

    @media screen and (max-width: 1020px) {
      h1{
        font-size: 40px;
        text-align: center;
        max-width: 100%;
      }
    }

  }

  /* Left section ends */
  /* -------------------------------- */
  /* Right section starts */

  .rightSection {
    .youtube-video-container {
      aspect-ratio: 16 / 9;
      width: 100%;
      aspect-ratio: 16 / 9;
    }

    .youtube-video {
      width: 100%;
      height: 100%;
    }
  }

  /* Right section starts */
`;
