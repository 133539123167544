import React from "react";
import { scroller } from "react-scroll";

import { data } from "../assets/Text.js";

import { Link } from "react-router-dom";

import styled from "styled-components";
import { motion } from "framer-motion";
import { textAnime } from "../Animations";
import { InputComponent } from "./InputComponent";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";

function scrollToVideo() {
  scroller.scrollTo("myScrollToElement", {
    duration: 1000,
    delay: 20,
    smooth: true,
    offset: -150,
  });
}

export const Heading2 = ({
  exists,
  setExists,
  modal,
  setModal,
  value,
  setValue,
  isLoading,
  setLoading,
  isError,
  setError,
}) => {
  return (
    <Styles
      variants={textAnime}
      initial="initial"
      animate="animate"
      className="setPadding"
    >
      <div>
        <h1>{data.mainTitle}</h1>
        <div className="description">
          <p>{data.mainDescription}</p>
        </div>
        <div className="input">
          <InputComponent
            modal={modal}
            setModal={setModal}
            value={value}
            setValue={setValue}
            exists={exists}
            setExists={setExists}
            isLoading={isLoading}
            setLoading={setLoading}
            isError={isError}
            setError={setError}
          />
        </div>
      </div>
    </Styles>
  );
};

const Styles = styled(motion.div)`
  .flex-btns {
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: center;
    button {
      min-width: 180px;
      height: 52px;
      background: rgba(0, 0, 0, 0.5);
      border: 2px solid #133381;
      transition: all 0.6s;
      color: #fff;
      :hover {
        background: #133381;
        color: #fff;
      }
    }
  }

  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  margin-top: 10px;
  .image {
    img {
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: calc(100vw - 10px);
      z-index: -100;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  div {
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }

  .description {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    p {
      margin: 20px 0;
      max-width: 500px;
      text-align: center;
      font-size: 24px;
    }
  }

  .input {
    margin-top: 10px;
  }
  .btn {
    background: rgba(0, 0, 0, 0.3);
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 20px 10px 10px;
    border: 2px solid #0c7e7e;
    border-radius: 2px;
    background: #0c7e7e;
    margin-bottom: 20px;

    color: #fff;
    transition: all 0.5s ease-out;
    font-weight: 700;

    :hover {
      background: transparent;

      svg {
        transform: rotate(90deg);
      }
    }
    svg {
      font-size: 28px;
      transition: all 0.3s ease-out;
    }
    span {
      margin-left: 10px;
    }
  }
  @media screen and (max-width: 600px) {
    .flex-btns {
      flex-direction: column;
      .btn {
        width: 180px;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
`;
