import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { HomePage } from "./HomePage";
import LoadingPage from "./Loading";
import "./Main.scss";

const Home = lazy(() => import("./HomePage"));
const Privacy = lazy(() => import("./PrivacyPage"));
const Prototype = lazy(() => import("./PrototypePage"));
const About = lazy(() => import("./AboutUsPage"));
const ExecutiveSummary = lazy(() => import("./ExecutiveSummaryPage"));
const PitchDeck = lazy(() => import("./PitchDeckPage"));
const Survey = lazy(() => import("./SurveyPage"));
const AthleteSurvey = lazy(() => import("./AthleteSurveyPage"));
const CoachSurvey = lazy(() => import("./CoachesSurveyPage"));

function App() {
  return (
    <Router>
      <Suspense fallback={<LoadingPage />}>
        <Switch>
          <Route path="/" exact component={Home}></Route>
          <Route path="/privacy" exact component={Privacy}></Route>
          <Route path="/prototype" exact component={Prototype}></Route>
          <Route path="/about" exact component={About}></Route>
          <Route path="/executive" exact component={ExecutiveSummary}></Route>
          <Route path="/pitch" exact component={PitchDeck}></Route>
          <Route path="/general" exact component={Survey}></Route>
          <Route path="/athletes" exact component={AthleteSurvey}></Route>
          <Route path="/coaches" exact component={CoachSurvey}></Route>
        </Switch>
      </Suspense>
    </Router>
  );
}

export default App;
