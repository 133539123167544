import React from "react";
import styled from "styled-components";

import Medal from "../assets/medal.svg";
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import SportsGymnasticsSharpIcon from '@mui/icons-material/SportsGymnasticsSharp';
import VisibilitySharpIcon from '@mui/icons-material/VisibilitySharp';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import AddchartIcon from '@mui/icons-material/Addchart';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import AssistWalkerIcon from '@mui/icons-material/AssistWalker';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import { Container } from "./styles";

export const appendScript = (scriptToAppend) => {
  const script = document.createElement("script");
  script.src = scriptToAppend;
  script.async = true;
  document.body.appendChild(script);
};

export default function Box3() {
  appendScript("https://static.airtable.com/js/embed/embed_snippet_v1.js");
  return (
    <Styles className="global-margin sections-column">
      <div className="leftSection">
        <div className="why-text">
          <h1 className="why">Benefits of joining early</h1>
        </div>
        <div class="grid">
          <div class="grid-item">
            <div class="box">
              <div class="icon">
                {/* <img src={Medal} alt="icon" /> */}
                <MoreTimeIcon className="muiIconClass" />
              </div>
              <div class="text">
                <p>Early access to new features</p>
              </div>
            </div>
          </div>
          <div class="grid-item">
            {" "}
            <div class="box">
              <div class="icon">
                {/* <img src={Medal} alt="icon" /> */}
                <ContentPasteSearchIcon className="muiIconClass" />
              </div>
              <div class="text">
                <p>Customized training program</p>
              </div>
            </div>
          </div>
          <div class="grid-item">
            {" "}
            <div class="box">
              <div class="icon">
                {/* <img src={Medal} alt="icon" /> */}
                {/* <VisibilitySharpIcon className="muiIconClass" /> */}
                <AddchartIcon className="muiIconClass"/>
              </div>
              <div class="text">
                <p>Boosted visibility on scout searches</p>
              </div>
            </div>
          </div>
          <div class="grid-item">
            {" "}
            <div class="box">
              <div class="icon">
                {/* <img src={Medal} alt="icon" /> */}
                <AssignmentIndIcon className="muiIconClass" />
              </div>
              <div class="text">
                <p>Free career counselling</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="rightSection">
        <iframe
          className="airtable-embed airtable-dynamic-height"
          src="https://airtable.com/embed/shrglUVX9jwvxybgw?backgroundColor=blue"
          style={{
            background: "transparent",
            border: "none",
            minWidth: "100%",
          }}
        ></iframe>
      </div>
    </Styles>
  );
}

const Styles = styled.div`
  max-width: 1300px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  .muiIconClass{
    font-size: 40px; 
  }
  .leftSection {
    width: 40%;
  }

  .why-text {
    width: 100%;
    margin-bottom: 60px;
    h1 {
      font-size: 64px;
      max-width: 450px;
    }
  }

  .rightSection {
    padding: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 60%;
    iframe {
      min-width: 350px;
    }
  }
  .grid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* create a 4-column grid */
    grid-gap: 30px; /* add some space between the grid items */
    max-width: 280px;
  }

  /* Make the grid items flex containers */
  .grid-item {
    border: 1px solid #ebebeb;
    box-shadow: 0px 8px 11px -4px rgb(0 0 0 / 10%);
    padding: 30px 20px;
    text-align: center;
    min-width: 200px;

    img {
      max-width: 40px;
    }
    p {
      margin-top: 10px;
    }
  }
  @media screen and (max-width: 1160px) {
    .rightSection {
      width: 50%;
    }
  }

  @media screen and (max-width: 930px) {
    flex-direction: column;
    .leftSection {
      width: 100%;
    }
    .grid-item {
      min-width: 300px;
    }
    .rightSection {
      width: 100% !important;
    }
  }

  @media screen and (max-width: 768px) {
    .grid {
      grid-template-columns: repeat(
        1,
        1fr
      ); /* create a 2-column grid on small screens */
      margin-right: 20px; //added ot bojo - change?
    }
  }

  @media screen and (max-width: 480px) {
    .grid {
      grid-template-columns: repeat(
        1,
        1fr
      ); /* create a 1-column grid on extra small screens */
    }
    @media only screen and (max-width: 1000px) {
      height: 100%;
    }
  }
`;
